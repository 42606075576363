import React, { FC } from 'react';
import cx from 'classnames';
import { IconProps } from '../Icon';
import { HtmlProps } from '../Html';
import { ActionButtonProps } from '../ActionButton';
import * as SC from './styled';
import { ListIconHtmlVariant } from './types';
export type ListIconHtmlProps = {
  className?: string;
  items?: {
    iconProps?: IconProps;
    htmlProps?: HtmlProps;
    ctaProps?: ActionButtonProps;
  }[];
  variant?: ListIconHtmlVariant;
};
const ListIconHtml: FC<ListIconHtmlProps> = props => {
  const {
    className,
    items,
    variant
  } = props;
  return items && items.length > 0 ? <SC.ListIconHtml className={cx('ListIconHtml', className)} $variant={variant}>
      {items.map((item, index) => <SC.Item key={`ListIconHtml-item-${index}`} $variant={variant}>
          {item.iconProps && (variant && variant === ListIconHtmlVariant.Register ? <SC.IconWrapper>
                <SC.StyledIcon $variant={variant} {...item.iconProps} />
              </SC.IconWrapper> : <SC.StyledIcon $variant={variant} {...item.iconProps} />)}
          <SC.ItemContent $variant={variant}>
            {item.htmlProps && <SC.StyledHtml $variant={variant} {...item.htmlProps} />}
            {item.ctaProps && <SC.StyledActionButton {...item.ctaProps} />}
          </SC.ItemContent>
        </SC.Item>)}
    </SC.ListIconHtml> : null;
};
export default ListIconHtml;